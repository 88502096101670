import React, { useMemo, useState } from 'react';
import { Wifi } from '@capacitor-community/wifi/src';
import { Network } from '@capacitor/network';
import { Center, FormControl, FormErrorMessage, Heading, Image, Input, Text, useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import semQrCodeImg from '../../../../../assets/images/sem_qr_code_scan.svg';
import { get } from '../../../../../common/api/api-helpers';
import ArcButton from '../../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import { timeout } from '../../../../../utils/common/common-utils';
import { selectSite } from '../../../../site/siteSlice';

type State = {
  isSerialNumInvalid: boolean;
  isSubmitting: boolean;
  serialNum: string;
};

const INITIAL_STATE: State = {
  isSerialNumInvalid: false,
  isSubmitting: false,
  serialNum: '',
};

/* istanbul ignore next -- @preserve */
export function MeterManualSerialEntry() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const toast = useToast({ isClosable: true, duration: null });
  const [state, setState] = useState<State>(INITIAL_STATE);
  const { serialNum, isSerialNumInvalid, isSubmitting } = state;
  const { t } = useTranslation();

  async function fetchAccountId(serialNum: string) {
    try {
      return await get<{ account_id: number }>(`/v1/sites/${site.site_id}/sense_account_id/${serialNum}`);
    } catch (e) {
      const error = e as AxiosError;
      console.error(e);

      if (error.response?.status === 404) {
        // The SEM may not yet be in our fleet, so a 404 can happen -- let the user proceed with an empty account id,
        // since in this case there should be no requirement for digest auth.
        return {
          account_id: null,
        };
      } else throw e;
    }
  }

  async function handleConnectToNetwork() {
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    // Before proceeding with SEM connections, we need to ensure the user is connected to the internet.
    // They could still be on the SEM wifi network if they retry at this point. Due to iOS restrictions,
    // we are unable to manually disconnect them from the SEM wifi network.
    const { connected: isConnectedToInternet } = await Network.getStatus();
    if (!isConnectedToInternet) {
      toast({
        title: t('Common.not connected to internet'),
        description: `${t('Set Up Hardware.make sure you are connected')} ${t(
          'Set Up Hardware.you may need to reconnect'
        )}`,
        status: 'error',
      });
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
      return;
    }

    try {
      const { password } = await get<{ password: string }>(`/v1/sense/${serialNum}/password`);
      // Before connecting to the Wi-Fi, we need to fetch the accountId for the site.
      // It's expected the user will have an internet connection at this point.
      const accountIdData = await fetchAccountId(serialNum);
      const { account_id: accountId } = accountIdData;

      try {
        const ssid = `Schneider Monitor ${serialNum}`;
        await Wifi.connect({ ssid, password, isHiddenSsid: false });
        // Here we wait a bit after connecting to the Wi-Fi before moving on to ensure we are connected -
        // - before immediately making any network requests
        await timeout(2000);
        try {
          // This ensures when coming from hardware details page, we can go back to the same page when going back
          search.set('connectedSSID', ssid);
          search.set('accountId', accountId?.toString() ?? '');
          navigate(`../connection_tests?${search.toString()}`);
        } catch (e) {
          Sentry.captureException(e);
          console.error(e);
          setState((prevState) => ({ ...prevState, isSubmitting: false }));
          toast({
            title: t('Set Up Hardware.error connecting to wifi'),
            description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
            status: 'info',
          });
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        setState((prevState) => ({ ...prevState, isSerialNumInvalid: true, isSubmitting: false }));
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast({
        title: t('Set Up Hardware.error getting sem connect details'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  }

  // This ensures when coming from hardware details page, we can go back to the same page when going back
  const urlSearchParamsToGoBack = useMemo(() => {
    search.set('direction', 'back');
    return search.toString();
  }, []);

  return (
    <SlidingAnimationPageBase
      title={t('Common.device setup')}
      backURL={`../connect_instructions?${urlSearchParamsToGoBack}`}
    >
      <Center flexDirection="column" px={3} mt={5} textAlign="center">
        <Image w={'70%'} my={4} src={semQrCodeImg} alt="Schneider Energy Monitor sketch" />

        <Heading mt={2}>{t('Set Up Hardware.type serial number of device')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.search your wifi for sem')}</Text>
        <Text mt={1}>{t('Set Up Hardware.for example sem wifi')}</Text>

        <FormControl mt={3} isInvalid={isSerialNumInvalid}>
          <Input
            data-testid={'meter-serial-number'}
            value={serialNum}
            onChange={(e) => setState((p) => ({ ...p, serialNum: e.target.value, isSerialNumInvalid: false }))}
            borderRadius={0}
            type="text"
            data-private
            background="white"
            _dark={{
              background: '#363E40',
            }}
            borderColor={'#9FA0A4'}
          />
          <FormErrorMessage>{t('Set Up Hardware.no wifi for serial')}</FormErrorMessage>
        </FormControl>

        <ArcButton
          data-testid={'meter-connect-to-network'}
          isLoading={isSubmitting}
          onClick={handleConnectToNetwork}
          isDisabled={!serialNum}
          mt={8}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Common.connect')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
