import React from 'react';
import { Box, BoxProps, Center, IconProps } from '@chakra-ui/react';

import { CircleIcon, VPPIcon } from '../../../styles/custom-icons';

type CustomVppIconProps = IconProps &
  BoxProps & {
    iconColor: string;
  };

export default function CustomVppIcon({ iconColor, ...rest }: CustomVppIconProps) {
  return (
    <Center flexDirection="column" textAlign="center" margin="auto" {...rest}>
      <Box w="100px" position={'absolute'}>
        <VPPIcon w="100%" h="100%" color={iconColor} data-testid={'vpp-icon'} />
      </Box>
      <CircleIcon color={iconColor} data-testid="circle-icon" zIndex={3} height="auto" width="auto" {...rest} />
    </Center>
  );
}
