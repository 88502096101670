import React from 'react';
import { Box, Grid, useColorModeValue } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import BarcodeScannerOverlay from './common/components/BarcodeScannerOverlay';
import { RootedDeviceWarningCard } from './common/components/RootedDeviceWarningCard';
import { COMPONENT_MIN_HEIGHT, IS_DEMO_LOGIN } from './common/constants';
import { useAppReady } from './common/hooks/use-app-ready';
import { useAppUpdate } from './common/hooks/use-app-update';
import { useAuthRedirect } from './common/hooks/use-auth-redirect';
import { useHighchartSetup } from './common/hooks/use-highchart-setup';
import { usePushNotificationSetup } from './common/hooks/use-push-notification-setup';
import { useRootedDeviceCheck } from './common/hooks/use-rooted-device-check';
import { useStatusBarStyleConfiguration } from './common/hooks/use-status-bar-style-configuration';
import { useViewScrollTop } from './common/hooks/use-view-scroll-top';
import DemoLoginBanner from './features/demo-login/DemoLoginBanner';

export interface LinkData {
  component: React.JSX.Element;
  path: string;
  requiresLogin: boolean;
}

function App() {
  const backgroundColor = useColorModeValue('#ffffff', '#111111');
  useHighchartSetup();
  useStatusBarStyleConfiguration();
  useViewScrollTop();
  usePushNotificationSetup();
  /* istanbul ignore next -- @preserve */
  const isRootedDevice = useRootedDeviceCheck();
  useAppUpdate();
  useAuthRedirect();
  const isLoaded = useAppReady();

  return isRootedDevice ? (
    /* istanbul ignore next -- @preserve */
    <RootedDeviceWarningCard />
  ) : (
    <Box background={backgroundColor}>
      <Box
        style={{ backdropFilter: 'blur(6px)' }}
        width="100vw"
        background={backgroundColor}
        zIndex={20}
        position="fixed"
        height={'env(safe-area-inset-top)'}
        top="0px"
      />

      {IS_DEMO_LOGIN && <DemoLoginBanner />}
      <BarcodeScannerOverlay />

      {isLoaded ? (
        <Box>
          <Grid minHeight={COMPONENT_MIN_HEIGHT} height="100%" templateColumns={'1fr'}>
            <Box>
              <Outlet />
            </Box>
          </Grid>
        </Box>
      ) : (
        <Box height={COMPONENT_MIN_HEIGHT}>
          <CenteredLoader />
        </Box>
      )}
    </Box>
  );
}

export default App;
