import {
  SEHomeBatteryIcon,
  SEHomeEnergyModeIcon,
  SEHomeGeneratorIcon,
  SEHomeLiveEnergyIcon,
  SEHomeSavingsIcon,
  SEHomeSolarIcon,
} from '../../../styles/custom-icons';
import { HomepageWidget } from './default-home-helpers';
import BatteryWidgetTile from './widget-tiles/BatteryWidgetTile';
import EnergyModesWidgetTile from './widget-tiles/EnergyModesWidgetTile';
import IncompleteWidgetTile from './widget-tiles/IncompleteWidgetTile';
import LiveEnergyWidgetTile from './widget-tiles/LiveEnergyWidgetTile';
import SavingsWidgetTile from './widget-tiles/SavingsWidgetTile';
import SolarWidgetTile from './widget-tiles/SolarWidgetTile';

export const HOMEPAGE_WIDGETS: HomepageWidget[] = [
  {
    // Note: This is a special case, where only the type and ID are used.
    id: 8,
    title: 'N/A',
    type: 'LIVE_ENERGY',
    Icon: SEHomeLiveEnergyIcon,
    link: 'N/A',
    status: 'N/A',
    Component: LiveEnergyWidgetTile,
  },
  // Note: EV Charger functionality isn't currently supported, so it is commented out.
  // {
  //   id: 1,
  //   title: 'EV Charger',
  //   type: 'EV_CHARGER',
  //   Icon: SEHomeEVIcon,
  //   link: '/ev_charger/home',
  //   status: '+23mi',
  // },
  {
    id: 2,
    title: 'Solar',
    type: 'SOLAR',
    Icon: SEHomeSolarIcon,
    link: '/solar/home',
    status: 'ON',
    Component: SolarWidgetTile,
  },
  {
    id: 3,
    title: 'Battery',
    type: 'BATTERY',
    Icon: SEHomeBatteryIcon,
    link: '/battery/home',
    status: '78%',
    Component: BatteryWidgetTile,
  },
  {
    id: 4,
    title: 'Generator',
    type: 'GENERATOR',
    Icon: SEHomeGeneratorIcon,
    link: '/generator/home',
    status: 'ON',
    Component: IncompleteWidgetTile,
  },
  // Note: The Eco Score page isn't implemented yet, so it is commented out.
  // {
  //   id: 5,
  //   title: 'Eco Score',
  //   type: 'ECO_SCORE',
  //   Icon: SEHomeEcoScoreIcon,
  //   link: '/eco/home',
  //   status: '89',
  // },
  {
    id: 6,
    title: `Today's Savings`,
    type: 'SAVINGS',
    Icon: SEHomeSavingsIcon,
    link: '/savings/home',
    status: '$7.22',
    Component: SavingsWidgetTile,
  },
  {
    id: 7,
    title: 'Energy Mode',
    type: 'ENERGY_MODE',
    Icon: SEHomeEnergyModeIcon,
    link: '/account/energy-modes/home',
    status: 'TOU',
    Component: EnergyModesWidgetTile,
  },
];
