import { isInteger } from 'lodash';

import {
  DAY_TYPES,
  DayType,
  SiteTariff,
  SiteTariffToSave,
  TariffRate,
  TariffType,
} from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import { TOU_RATE_TYPE_TO_FORM_TERM_MAP } from './tariff-constants';
import { Season } from './tariff-season/tariff-season-helpers';
import { Rate, TariffData, TariffRatesPerType, TOUTariffRatesPerType } from './tariff-types';
import { TOU_TARIFF_FORM_KEYS } from './tou-tariff/tou-tariff-constants';

/**
 * @description Format date to be used in season based on api requirements
 * @param month - month to be used in season end date
 * @param date - date to be used in season end date
 * @param offset - add X days in the date
 */
export const adjustSeasonDate = (month: number, date: number, offset?: number) => {
  const formattedDate = new Date(new Date().getFullYear(), month, date);

  // date is not inclusive in api, hence add 1 to include the date if required
  if (offset) formattedDate.setDate(formattedDate.getDate() + offset);

  return formattedDate;
};

const mapTariffApiRateToForm = (
  { id, season, charge_period, charge_type, charge_class, transaction_type, rate_bands, time_of_use }: TariffRate,
  dataIndex: number | null = null
) => {
  return {
    id,
    ...(dataIndex !== null ? { seasonIndex: season?.season_index || dataIndex + 1 } : {}),
    chargePeriod: charge_period,
    chargeType: charge_type,
    chargeClass: charge_class,
    transactionType: transaction_type,
    timeOfUse: time_of_use
      ? {
          touName: time_of_use.tou_name,
          touRateType: time_of_use.tou_rate_type,
          periods: time_of_use.periods.map(({ days, from_hour, from_minute, to_hour, to_minute, public_holiday }) => ({
            days,
            fromHour: from_hour,
            fromMinute: from_minute,
            toHour: to_hour,
            toMinute: to_minute,
            publicHoliday: !!public_holiday,
          })),
        }
      : null,
    rateBands: rate_bands.map(
      ({
        id,
        sequence_number,
        rate,
        has_consumption_limit,
        consumption_upper_limit,
        has_demand_limit,
        demand_upper_limit,
      }) => ({
        id,
        sequenceNumber: sequence_number,
        rate,
        hasConsumptionLimit: !!has_consumption_limit,
        consumptionUpperLimit: consumption_upper_limit,
        hasDemandLimit: !!has_demand_limit,
        demandUpperLimit: demand_upper_limit,
      })
    ),
  };
};

const mapTariffFormRateToApi = (
  { id, chargeType, seasonIndex, chargeClass, chargePeriod, transactionType, rateBands, timeOfUse }: Rate,
  tariffType: TariffType = 'FLAT'
): TariffRate => {
  return {
    ...(id ? { id } : {}), // only append id if it exists i.e. for POST/PUT
    ...(isInteger(seasonIndex) ? { season_index: seasonIndex } : {}), // only append season_index if it exists
    charge_type: chargeType,
    charge_class: chargeClass,
    ...(chargePeriod ? { charge_period: chargePeriod } : {}),
    ...(transactionType ? { transaction_type: transactionType } : {}),
    time_of_use: timeOfUse
      ? {
          tou_name: timeOfUse.touName,
          tou_rate_type: timeOfUse.touRateType,
          periods: timeOfUse.periods.map(({ days, fromHour, fromMinute, toHour, toMinute, publicHoliday }) => ({
            days,
            from_hour: fromHour,
            from_minute: fromMinute,
            to_hour: toHour,
            to_minute: toMinute,
            public_holiday: publicHoliday,
          })),
        }
      : null,
    rate_bands: rateBands.map(
      ({ id, rate, hasConsumptionLimit, hasDemandLimit, consumptionUpperLimit, demandUpperLimit }, idx, arr) => {
        const isTieredTariff = tariffType === 'TIERED';
        const isLastIndex = idx === arr.length - 1;

        const newConsumptionUpperLimit = isLastIndex && isTieredTariff ? null : consumptionUpperLimit;
        return {
          ...(id ? { id } : {}),
          sequence_number: idx + 1,
          rate: rate ?? 0,
          has_consumption_limit: hasConsumptionLimit,
          has_demand_limit: hasDemandLimit,
          ...(consumptionUpperLimit ? { consumption_upper_limit: newConsumptionUpperLimit } : {}),
          ...(demandUpperLimit ? { demand_upper_limit: demandUpperLimit } : {}),
        };
      }
    ),
  };
};

export const getTariffRatesPerType = (
  seasons: Season[],
  rates: TariffRate[],
  tariffType: TariffType
): TariffRatesPerType[] => {
  return seasons.map((season, dataIndex) => {
    return rates
      .filter((rate) => rate.season?.season_index === season.seasonIndex)
      .reduce(
        (acc, tariffRate) => {
          const rate = mapTariffApiRateToForm(tariffRate, dataIndex);

          // segregate rates based on charge type or transaction type
          // this is will help in rendering dynamic forms i.e. tiered/tou tariff
          if (rate.transactionType === 'BUY_IMPORT') {
            // for tou tariffs, segregate rates based on tou rate type
            if (tariffType === 'TOU' && rate.timeOfUse) {
              const { touRateType, periods } = rate.timeOfUse;
              if (touRateType) {
                periods.map((period) => {
                  // find day type based on days in period
                  let dayType: DayType = 'weekday';
                  if (period.days.includes('SAT')) dayType = 'weekend';

                  // if it is more than 5 days, override to all week
                  if (period.days.length > 5) dayType = 'allWeek';
                  const formTerm = TOU_RATE_TYPE_TO_FORM_TERM_MAP[touRateType];
                  acc[dayType][formTerm].push(rate);
                });
              }
            } else {
              // for flat/tiered tariffs, add to import rates
              acc.import.push(rate);
            }
          } else if (rate.transactionType === 'SELL_EXPORT') {
            acc.export.push(rate);
          }
          return acc;
        },
        {
          import: [] as Rate[],
          export: [] as Rate[],
          weekday: {
            peak: [],
            offPeak: [],
            partialPeak: [],
          } as TOUTariffRatesPerType,
          weekend: {
            peak: [],
            offPeak: [],
            partialPeak: [],
          } as TOUTariffRatesPerType,
          allWeek: {
            peak: [],
            offPeak: [],
            partialPeak: [],
          } as TOUTariffRatesPerType,
        }
      );
  });
};

export const mapTariffApiToForm = (
  { id, tariff_effective_date: effectiveDate, tariff }: SiteTariff,
  utility?: Partial<TariffData['tariff']['utility']>
): TariffData => {
  const { tariff_type, plan_name, rates, retailer, distributor_id, holiday_country, holiday_subdiv } = tariff;

  const seasons =
    tariff.seasons?.map(({ name, season_index, from_month, from_date, to_month, to_date }) => {
      // backend season end date is exclusive, hence remove 1 day to get the correct date
      const endDate = adjustSeasonDate(to_month - 1, to_date, -1);
      return {
        name,
        seasonIndex: season_index,
        fromMonth: from_month - 1,
        fromDate: from_date,
        toMonth: endDate.getMonth(),
        toDate: endDate.getDate(),
      };
    }) ?? [];

  const deliveryChargeRate = rates.find((rate) => rate.charge_type === 'FIXED_PRICE');

  return {
    id,
    effectiveDate,
    tariff: {
      tariffType: tariff_type,
      planName: plan_name || '',
      deliveryCharge: deliveryChargeRate ? mapTariffApiRateToForm(deliveryChargeRate) : undefined,
      seasons,
      rates: getTariffRatesPerType(seasons, rates, tariff_type),
      retailer: {
        id: retailer?.id ?? 0,
        name: retailer?.name ?? '',
      },
      utility: {
        id: utility?.id ?? 0,
        name: utility?.name ?? '',
        state: utility?.state ?? '',
      },
      distributorId: distributor_id,
      holidayCountry: holiday_country,
      holidaySubdiv: holiday_subdiv,
    },
  };
};

export const mapTariffDataToApi = ({ effectiveDate, tariff, id }: TariffData): SiteTariffToSave => {
  const {
    planName,
    retailer,
    distributorId,
    holidayCountry,
    holidaySubdiv,
    rates,
    seasons,
    tariffType,
    deliveryCharge,
  } = tariff;
  return {
    ...(id ? { id } : {}), // only append id if it exists i.e. for POST/PUT
    tariff_effective_date: effectiveDate,
    tariff: {
      plan_name: planName,
      tariff_type: tariffType,
      retailer_id: retailer.id || null,
      ...(distributorId ? { distributor_id: distributorId } : {}),
      ...(holidayCountry ? { holiday_country: holidayCountry } : {}),
      ...(holidaySubdiv ? { holiday_subdiv: holidaySubdiv } : {}),
      seasons: seasons.map(({ name, seasonIndex, fromDate, fromMonth, toDate, toMonth }) => {
        // toDate is mutually exclusive in api, hence add 1 to include the date
        const endDate = adjustSeasonDate(toMonth, toDate, 1);

        return {
          name: name,
          ...(id ? { tariff_id: id } : {}), // only append id if it exists i.e. for POST/PUT
          season_index: seasonIndex,
          from_date: fromDate,
          from_month: fromMonth + 1, // api wants month as 1-12
          to_date: endDate.getDate(),
          to_month: endDate.getMonth() + 1, // api wants month as 1-12
        };
      }),
      rates: [
        ...rates
          .map((seasonRates) => {
            const allRates = [
              ...seasonRates.import,
              ...seasonRates.export,
              ...DAY_TYPES.map((dayType) =>
                TOU_TARIFF_FORM_KEYS.map((formKey) => seasonRates?.[dayType]?.[formKey] ?? []).flat()
              ).flat(),
            ];
            return allRates.map((rate) => mapTariffFormRateToApi(rate));
          })
          .flat(),
        // Since we separate delivery charge from other rates
        // in the form, add delivery charge to rates in api
        ...(deliveryCharge ? [mapTariffFormRateToApi(deliveryCharge)] : []),
      ],
    },
  };
};

/**
 *
 * @param amount dollar value to be formatted
 * @returns cents if amount is less than 1, else dollars
 */
export const formatDollars = (amount: number) => {
  const isCents = amount < 1;
  if (isCents) return amount * 100 + '¢';
  return '$' + amount;
};
