import React, { useMemo } from 'react';
import { Box, Center, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import ArcButton from '../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { selectSite } from '../../../features/site/siteSlice';
import { ActiveVPP } from './ActiveVPP';
import CustomVppIcon from './CustomVPPIcon';
import { useGetVppStatus } from './helpers';
import { JoinVPPProgram } from './JoinVPPProgram';
import { VPPApplicationGuide } from './VPPApplicationGuide';

// The following component is responsible for facilitating the user's journey through VPP participation.
// The user's journey is as follows:
// 1️⃣ To be eligible to join a program, the user must provide their utility provider and utility account number.
// 2️⃣ The user will receive a notification from Saturn Cloud when they are eligible to join a VPP program.
// 3️⃣ The user accepts the offer in SE Home. Their enrolment is pending and get a notification if approved/denied.
// 4️⃣ Once the user's inverter is enrolled in a VPP program, they can view current & upcoming VPP events and opt out.

export function VPPHome() {
  const { devices } = useSelector(selectSite);
  const [search] = useSearchParams();
  const inverterId = useMemo(() => devices!.find((device) => device.device_type === 'INVERTER')?.id, [devices]);
  const { status, isLoaded } = useGetVppStatus({ inverterId: inverterId! });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dangerColor = useColorModeValue('#FF5B44', '#FF5B44');
  const warningColor = useColorModeValue('orange', 'orange');

  const backURL = useMemo(() => {
    return search.get('backURL') ?? `../../../account/dashboard?direction=back`;
  }, [search]);

  let contents;

  switch (status) {
    case 'APPLY_FOR_VPP':
      contents = (
        <VPPApplicationGuide>
          <ArcButton
            arcColor="#3DCD58"
            minWidth="80%"
            mb={4}
            py={6}
            onClick={() => navigate('../apply?direction=forward')}
            data-testid="continue-button"
          >
            {t('Common.get started')}
          </ArcButton>
        </VPPApplicationGuide>
      );
      break;
    case 'ENROLLED':
      contents = <ActiveVPP />;
      break;
    case 'ELIGIBLE':
      contents = <JoinVPPProgram />;
      break;
    case 'PENDING':
      contents = (
        <>
          <CustomVppIcon maxH="175px" iconColor={warningColor} />
          <Heading my={2} textAlign="center">
            {t('VPP.enrolment pending title')}
          </Heading>

          <Text mb={4} textAlign="center">
            {t('VPP.enrolment pending description 1', {
              utilityName: 'PG&E',
              programName: 'Emergency Load Reduction Program (ELRP)',
            })}
          </Text>
          <Text mb={4} textAlign="center">
            {t('VPP.enrolment pending description 2')}
          </Text>
          <Center>
            <ArcButton
              arcColor={dangerColor}
              w={'80%'}
              mt={6}
              onClick={() => navigate(`../../../account/support`)}
              data-testid="contact-support-button"
            >
              {t('Common.contact support')}
            </ArcButton>
          </Center>
        </>
      );
      break;
    case 'DENIED':
      contents = (
        <>
          <CustomVppIcon maxH="175px" iconColor={dangerColor} />
          <Heading my={2} textAlign="center">
            {t('VPP.enrolment denied title')}
          </Heading>
          <Text textAlign="center" mb={4}>
            {t('VPP.enrolment denied description')}
          </Text>
          <Center>
            <ArcButton
              arcColor={dangerColor}
              w={'80%'}
              mt={6}
              onClick={() => navigate(`../../../account/support`)}
              data-testid="contact-support-button"
            >
              {t('Common.contact support')}
            </ArcButton>
          </Center>
        </>
      );
      break;
    case 'NO_OFFERS_AVAILABLE':
      contents = (
        <>
          <CustomVppIcon maxH="175px" iconColor={dangerColor} />
          <Heading my={2} textAlign="center">
            {t('VPP.no programs available title')}
          </Heading>
          <Text textAlign="center" mb={2}>
            {t('VPP.no programs available description 1')}
          </Text>
          <Text textAlign="center" mb={2}>
            {t('VPP.no programs available description 2')}
          </Text>
        </>
      );
      break;
    default:
      contents;
  }

  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('VPP.title')}>
      <Box mx={4} mb={4}>
        {isLoaded ? contents : <CenteredLoader text={`${t('Common.loading')}...`} mt="50%" />}
      </Box>
    </SlidingAnimationPageBase>
  );
}
