import React, { useMemo } from 'react';
import { ChevronRightIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Center,
  ComponentWithAs,
  Divider,
  Flex,
  Heading,
  IconProps,
  Image,
  SimpleGrid,
  Skeleton,
  SkeletonCircle,
  Text,
} from '@chakra-ui/react';
import { SerializedError } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { formatSecondsToHHMM } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import batteryAnimationDark from '../../assets/images/battery_animation_750.gif';
import { RTKQError } from '../../common/api/api-helpers';
import DataSummaryCard from '../../common/components/DataSummaryCard';
import SEHomeCard from '../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { createBatteryReserveIcon } from '../../styles/create-battery-reserve-icon';
import { createCirclePercentageFilledIcon } from '../../styles/create-circle-percentage-filled-icon';
import { BatteryChargingIcon, EmptyBatteryIcon, ExclamationIcon } from '../../styles/custom-icons';
import { GENERIC_BATTERIES_MANUFACTURER_IDS } from './constants';
import { BatteryLiveData, BatteryStatus, useLiveBatteryData } from './liveBatteryDataApi';

interface Props {
  data: BatteryLiveData;
  isLoaded?: boolean;
  error?: RTKQError | SerializedError;
}

export function BatteryHome() {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const { data, isLoading, error } = useLiveBatteryData();
  const isLoaded = !isLoading;

  const backURL = useMemo(() => search.get('backURL') ?? `../../home?direction=back`, [search]);

  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('Battery.battery')} _dark={{ bg: '#111111' }}>
      <Center flexDirection="column" my={8} mx={3}>
        {error && 'status' in error && error.status === 422 ? (
          <>
            <InfoIcon mb={2} color={'red.500'} w={50} h={50} />
            <Heading textAlign={'center'} data-testid="unsupported-site-error">
              {t('Battery.this home does not have a battery')}
            </Heading>
          </>
        ) : (
          <>
            <BatteryIcon data={data} isLoaded={isLoaded} />

            <SimpleGrid gap={3} row={2} w="100%" alignItems="center" my={3}>
              <BatteryTiles data={data} isLoaded={isLoaded} error={error} />
              <BatteryButtons data={data} />
            </SimpleGrid>
          </>
        )}
      </Center>
    </SlidingAnimationPageBase>
  );
}

type BatteryUIConfig = { color: string; Icon?: ComponentWithAs<'svg', IconProps> };

const BATTERY_STATUS_UI_CONFIG: Record<BatteryStatus, BatteryUIConfig> = {
  CHARGING: {
    color: '#42B4E6',
    Icon: BatteryChargingIcon,
  },
  DISCHARGING: {
    color: '#42B4E6',
  },
  EMPTY: {
    color: '#9FA4AF',
    Icon: EmptyBatteryIcon,
  },
  ERROR: {
    color: '#FF5B44',
    Icon: ExclamationIcon,
  },
  IDLE: {
    color: '#42B4E6',
    Icon: BatteryChargingIcon,
  },
};

function BatteryIcon({ data, isLoaded }: Props) {
  const { t } = useTranslation();

  const { Icon: ConfigIcon, color } = useMemo(() => {
    return BATTERY_STATUS_UI_CONFIG[data.status] || {};
  }, [data.status, BATTERY_STATUS_UI_CONFIG]);
  const Icon = useMemo(() => {
    if (!ConfigIcon && data.status === 'DISCHARGING') {
      return createBatteryReserveIcon((data?.battery_soc_fraction ?? 0) * 100);
    }
    return ConfigIcon;
  }, [data.status, data?.battery_soc_fraction, createBatteryReserveIcon, ConfigIcon]);

  const CircleIcon = useMemo(() => {
    return createCirclePercentageFilledIcon((data?.battery_soc_fraction ?? 1) * 100);
  }, [data?.battery_soc_fraction, createCirclePercentageFilledIcon]);

  return (
    <>
      <SkeletonCircle w={[275, 400]} h={[275, 400]} isLoaded={!!isLoaded}>
        <Center position="relative" px={3} flexDirection="column" textAlign="center">
          {data.status === 'CHARGING' && (
            <Image
              data-testid="battery-charging-animation"
              position="absolute"
              w={[250, 375]}
              h={[250, 375]}
              src={batteryAnimationDark}
              _light={{ display: 'none' }} // @TODO: Get animation for light mode
            />
          )}

          {!!Icon && <Icon w={[140, 200]} h={[140, 200]} color={color} pos="absolute" data-testid="status-icon" />}
          <CircleIcon h="100%" w="100%" color={color} data-testid="circle-icon" zIndex={3} />
        </Center>
      </SkeletonCircle>

      <Skeleton isLoaded={isLoaded} mt={2} minHeight={58} minW={250}>
        <Text textAlign={'center'} fontSize={28} fontWeight={500} data-testid="status-title">
          {t(`Battery.states.${data.status}.title`)}
        </Text>
        <Text textAlign={'center'} fontSize={16} fontWeight={400} data-testid="status-text" lineHeight={1}>
          {t(`Battery.states.${data.status}.text`)}
        </Text>
      </Skeleton>
    </>
  );
}

function BatteryTiles({ data, isLoaded, error }: Props) {
  const { t } = useTranslation();

  const batteryDurationRemaining = useMemo(() => {
    if (data.status === 'EMPTY') {
      return '0';
    }
    if (data && !error) {
      return formatSecondsToHHMM(Math.abs(data?.battery_duration_sec ?? 0));
    }
    return '0';
  }, [data, error, formatSecondsToHHMM]);

  const batteryDurationTitle = useMemo(() => {
    switch (data.status) {
      case 'EMPTY':
        return t('Battery.battery charge');
      case 'CHARGING':
        return t('Battery.time until charged');
      default:
        return t('Battery.time remaining');
    }
  }, [data.status, t]);
  return (
    <SimpleGrid gap={3} columns={2}>
      <DataSummaryCard
        title={batteryDurationTitle}
        isLoaded={!!isLoaded}
        value={batteryDurationRemaining}
        suffix={data.status === 'EMPTY' ? '%' : ''}
        data-testid="battery-telemetry-card"
        h="100%"
      />
      <DataSummaryCard
        title={t('Common.current power')}
        isLoaded={!!isLoaded}
        value={Intl.NumberFormat('en', { notation: 'compact' }).format(
          data.status == 'ERROR' || data.status == 'EMPTY' ? 0 : Math.abs(data?.battery ?? 0)
        )}
        suffix="kW"
        data-testid="current-power-card"
        h="100%"
      />
    </SimpleGrid>
  );
}

function BatteryButtons({ data }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Skeleton isLoaded={true} borderRadius={5} borderBottomRightRadius={'25px'} m={0}>
      <SEHomeCard borderTopLeftRadius={5} borderBottomRightRadius={'25px'} textAlign={'center'}>
        <Flex
          w="100%"
          py={2}
          as="button"
          onClick={() => {
            navigate(`../set-reserve-capacity`);
          }}
          data-testid="battery-reserve-button"
        >
          <Text>{t('Battery.set battery reserve')}</Text>
          <ChevronRightIcon ml="auto" w={6} h={6} />
        </Flex>
        {/* TODO For Activity button remove when API provides historical data */}
        {!GENERIC_BATTERIES_MANUFACTURER_IDS.includes(data?.manufacturerId) && (
          <>
            <Divider borderColor={'rgba(151, 151, 151, 0.3)'} my={4} />
            <Flex
              w="100%"
              py={2}
              as="button"
              onClick={() => {
                navigate(`../../activity?source=battery`);
              }}
              data-testid="battery-activity-button"
            >
              <Text>{t('Battery.battery activity')}</Text>
              <ChevronRightIcon ml="auto" w={6} h={6} />
            </Flex>
          </>
        )}
      </SEHomeCard>
    </Skeleton>
  );
}
