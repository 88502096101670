import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from '../../common/api/api-helpers';

export const baseApi = createApi({
  baseQuery: customBaseQuery(),
  endpoints: () => ({}),
  tagTypes: [
    'NotificationSettings',
    'Notifications',
    'Notification',
    'Devices',
    'LiveData',
    'MeterLiveData',
    'InverterLiveData',
    'AylaLiveData',
    'Usage',
    'Costs',
    'SunriseSunset',
    'Savings',
    'SwitchSchedules',
    'Investments',
    'EnergyModes',
    'Switches',
    'Weather',
    'Invitations',
    'SiteUsers',
    'Retailers',
    'Tariffs',
    'Utilities',
    'SwitchGroups',
    'SiteConsents',
    'GenericBatteryReserve',
    'SaturnBatteryReserve',
    'LocalMatterDevices',
    'VPPUtilities',
    'VPPProgramOffers',
    'VPPUtilityStatus',
    'VPPProgramsStatus',
  ],
});

export const {} = baseApi;
