import { InputProps } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ChargePeriod, TariffType, TOURateType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import { COMPONENT_MIN_HEIGHT, TOP_NAV_SPACING_AFFORDANCE } from '../../common/constants';
import { selectSite } from '../site/siteSlice';
import { getDefaultFlatTariffRates } from './flat-tariff/flat-tariff-helpers';
import { useTranslatedEmptySeasonsTemplate } from './tariff-season/tariff-season-helpers';
import { Rate, TariffData, TariffRatesPerType, TariffTypeConfig } from './tariff-types';
import { getDefaultTieredTariffRates } from './tiered-tariff/tiered-tariff-helpers';
import { TOUTariffFormKey } from './tou-tariff/tou-tariff-constants';
import { getDefaultTOUTariffRates } from './tou-tariff/tou-tariff-helpers';

export const TARIFF_TYPE_CONFIG: TariffTypeConfig[] = [
  {
    type: 'FLAT',
  },
  {
    type: 'TIERED',
  },
  {
    type: 'TOU',
  },
];

export const DEFAULT_MONTHLY_DELIVERY_CHARGE_RATE: Rate = {
  chargeType: 'FIXED_PRICE',
  chargeClass: 'DISTRIBUTION',
  chargePeriod: 'MONTHLY',
  rateBands: [
    {
      sequenceNumber: 1,
      rate: undefined,
      hasConsumptionLimit: false,
      hasDemandLimit: false,
    },
  ],
};

export const useTranslatedDefaultTariffData = (): TariffData => {
  const site = useSelector(selectSite);
  const EMPTY_SEASONS_TEMPLATE = useTranslatedEmptySeasonsTemplate();
  return {
    effectiveDate: format(new Date(), 'yyyy-MM-dd'),
    tariff: {
      tariffType: 'FLAT',
      planName: '',
      deliveryCharge: DEFAULT_MONTHLY_DELIVERY_CHARGE_RATE,
      rates: [getDefaultFlatTariffRates(1), getDefaultFlatTariffRates(2)],
      seasons: EMPTY_SEASONS_TEMPLATE,
      retailer: {
        id: 0,
        name: '',
      },
      utility: {
        id: 0,
        name: '',
        state: '',
      },
      distributorId: 0,
      holidayCountry: '',
      holidaySubdiv: '',
    },
    zipCode: site.post_code,
  };
};

export const TOU_RATE_TYPE_TO_FORM_TERM_MAP: Record<TOURateType, TOUTariffFormKey> = {
  PEAK: 'peak',
  OFF_PEAK: 'offPeak',
  PARTIAL_PEAK: 'partialPeak',
  SINGLE: 'peak', // not yet known how to handle single rate type
};

export const TARIFF_TO_DEFAULT_FUNCTION_MAP: Record<TariffType, (seasonIndex: number) => TariffRatesPerType> = {
  FLAT: getDefaultFlatTariffRates,
  TIERED: getDefaultTieredTariffRates,
  TOU: getDefaultTOUTariffRates,
  REAL_TIME: () => {
    throw new Error('SE Home does not support real-time rates!');
  },
};

export const CHARGE_PERIOD_TO_LABEL_MAP: Record<ChargePeriod, string> = {
  MONTHLY: 'Monthly',
  DAILY: 'Daily',
  QUARTERLY: 'Quarterly',
  ANNUALLY: 'Annually',
};

export const useTranslatedCommonInputProps = (): InputProps => {
  const { t } = useTranslation();
  return {
    borderRadius: '1px',
    borderColor: '#9FA0A4',
    py: 6,
    _dark: { background: '#27282A' },
    size: 'md',
    placeholder: `${t('Energy Rates.enter price per kwh in usd')}...`,
    _placeholder: { color: '#929497' },
    type: 'number',
    min: 0,
  };
};

export const MIN_TARIFF_PAGE_HEIGHT = `calc(${COMPONENT_MIN_HEIGHT} - ${TOP_NAV_SPACING_AFFORDANCE})`;

export const MAX_TARIFF_PAGE_WIDTH = `calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right))`;
