import { useMemo } from 'react';

import {
  RespondToProgramOffer,
  useGetVppProgramOffersQuery,
  useGetVppProgramsStatusQuery,
  useGetVppUtilityStatusQuery,
} from './vppApi';

export type VPPStatus = 'APPLY_FOR_VPP' | 'ENROLLED' | 'ELIGIBLE' | 'PENDING' | 'DENIED' | 'NO_OFFERS_AVAILABLE';

interface Props {
  inverterId: number;
}

// We need to consolidate the various sources of VPP status data for a Saturn inverter to determine the state in the UI.
// Unfortunately these APIs are not very robust, so the order of operations matters here.
export function useGetVppStatus({ inverterId }: Props): { status: VPPStatus | undefined; isLoaded: boolean } {
  const { data: vppUtilityStatus, isLoading: isVppUtilityLoading } = useGetVppUtilityStatusQuery(inverterId);
  const { data: programsStatus, isLoading: isProgramStatusLoading } = useGetVppProgramsStatusQuery(inverterId);
  const { data: programOffers = [], isLoading: isProgramOffersLoading } = useGetVppProgramOffersQuery(inverterId);
  const isLoaded = !isProgramStatusLoading && !isVppUtilityLoading && !isProgramOffersLoading;

  const VPPStatus = useMemo(() => {
    if (isLoaded) {
      if (!vppUtilityStatus) {
        // If utility details for VPP haven't been provided yet, show the application screen
        return 'APPLY_FOR_VPP';
      }
      // Currently participating in a VPP program is limited to just one, however the API supports multiple.
      // Not ideal for the current UI, however for now we assume that the user will only ever be enrolled in one program
      if (programsStatus?.find((programStatus) => programStatus.equipment_program?.status === 'Enrolled')) {
        // The inverter is enrolled in a program, show the 'active program' screen
        return 'ENROLLED';
      }
      if (programsStatus?.find((programStatus) => programStatus.equipment_program?.status === 'Eligible')) {
        // The inverter is eligible to join a specific program, show the 'join program' screen
        return 'ELIGIBLE';
      }
      if (programsStatus?.find((programStatus) => programStatus.equipment_program?.status === 'Pending')) {
        // Enrolment into a specific program is pending, show the pending screen
        return 'PENDING';
      }
      if (programsStatus?.find((programStatus) => programStatus.equipment_program?.status === 'Ineligible')) {
        // Enrolment into a specific program was denied, show the denied screen
        return 'DENIED';
      }
      if (programOffers.length === 0) {
        return 'NO_OFFERS_AVAILABLE';
      }
      if (programOffers.length > 0) {
        // A via an existing program status for their equipment (above) or alteneratively via program offers here
        return 'ELIGIBLE';
      }
    }
  }, [isLoaded, vppUtilityStatus, programsStatus, programOffers]);

  return useMemo(() => {
    return {
      status: VPPStatus,
      isLoaded,
    };
  }, [VPPStatus, isLoaded]);
}

// For a given inverter, this provides the list of programs a user is eligible to join.
// We must consolidate 2 sources of data in order to have all the data required to respond to the VPP program offer.
export function useGetEligibleVppProgramsToJoin({ inverterId }: Props) {
  const { data: programsStatus = [], isLoading: isProgramStatusLoading } = useGetVppProgramsStatusQuery(inverterId);
  const { data: programOffers = [], isLoading: isProgramOffersLoading } = useGetVppProgramOffersQuery(inverterId);
  const isLoaded = !isProgramStatusLoading && !isProgramOffersLoading;

  const vppProgramOffers: RespondToProgramOffer[] | undefined = useMemo(() => {
    if (isLoaded) {
      const programs = programsStatus.filter((programStatus) => programStatus.equipment_program?.status === 'Eligible');
      return programs.map((program) => {
        // This provides all the necessary data required to response to a VPP program offer
        return {
          site_program_notification_mrid: programOffers.find(
            (programOffer) => programOffer.program.m_rid === program.equipment_program!.program.m_rid
          )!.m_rid,
          program_mrid: program.equipment_program!.program.m_rid,
          program_name: program.equipment_program!.program.name,
          battery_mrid: program.equipment.m_rid,
        };
      });
    }
  }, [isLoaded, programsStatus, programOffers]);

  return useMemo(() => {
    return {
      offers: vppProgramOffers,
      isLoaded,
    };
  }, [vppProgramOffers, isLoaded]);
}
