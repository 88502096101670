import { useSelector } from 'react-redux';

import { Device } from 'clipsal-cortex-types/src/api';

import { selectSite } from '../../site/siteSlice';
import { useSource } from './use-source';

export function useInitialSelectedPowerSources(devices: Device[]) {
  const source = useSource();
  const { is_consumption_site } = useSelector(selectSite);

  if (source) {
    return {
      solar: source === 'solar',
      battery: source === 'battery',
      // generator: source === 'generator',
      grid: source === 'grid',
    };
  }

  const hasBattery = devices.some((d) => d.device_type === 'BATTERY_PACK');
  const hasInverter = devices.some((d) => d.device_type === 'INVERTER');
  const hasMeter = devices.some((d) => d.device_type === 'METER');

  return {
    solar: hasInverter || (hasMeter && !is_consumption_site),
    battery: hasBattery,
    generator: false,
    grid: (hasBattery && hasInverter) || hasMeter,
  };
}
