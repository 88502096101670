import React, { useMemo, useState } from 'react';
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsThreeDots } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import ArcButton from '../../../common/components/ArcButton';
import OptionSelectAlertDrawer from '../../../common/components/OptionSelectAlertDrawer';
import { selectSite } from '../../site/siteSlice';
import PrivacyNoticeModal from '../settings/privacy/PrivacyNoticeModal';
import TermsOfUseModal from '../TermsOfUseModal';
import CustomVppIcon from './CustomVPPIcon';
import { useGetEligibleVppProgramsToJoin } from './helpers';
import { RespondToProgramOffer, useRespondToVppProgramOfferMutation } from './vppApi';

export function JoinVPPProgram() {
  const { devices } = useSelector(selectSite);
  const inverterId = useMemo(() => devices!.find((device) => device.device_type === 'INVERTER')?.id, [devices]);
  const textColor = useColorModeValue('customGrey.900', 'customGrey.300');
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const linkColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const { isOpen: isTermsOpen, onOpen: onTermsOpen, onClose: onTermsClose } = useDisclosure();
  const { isOpen: isPrivacyOpen, onOpen: onPrivacyOpen, onClose: onPrivacyClose } = useDisclosure();
  const { isOpen: isOptionDrawerOpen, onOpen: onOpenOptionDrawer, onClose: onCloseOptionDrawer } = useDisclosure();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const { offers = [], isLoaded } = useGetEligibleVppProgramsToJoin({ inverterId: inverterId! });
  const [selectedOffer, setSelectedOffer] = useState<RespondToProgramOffer>();
  const [respondToVppProgramOffer] = useRespondToVppProgramOfferMutation();

  function handleSelectOptionDrawerOption(value: string) {
    const offer = offers.find((offer) => offer.program_mrid === value);
    offer && setSelectedOffer({ ...offer, is_accepted: true });
    onCloseOptionDrawer();
  }

  // Its difficult to trust the API data here, we need to ensure the consolidated data has what we need to show options
  const programOptions = useMemo(
    () =>
      offers
        .filter((offer) => !!offer.program_mrid && !!offer.program_name)
        .map((offer) => ({ value: offer.program_mrid!, label: offer!.program_name! })),
    [offers]
  );

  async function handleSubmit() {
    try {
      const body: RespondToProgramOffer = {
        site_program_notification_mrid: selectedOffer!.site_program_notification_mrid,
        program_mrid: selectedOffer!.program_mrid,
        battery_mrid: selectedOffer!.battery_mrid,
        is_accepted: true,
      };
      await respondToVppProgramOffer({ inverterId: inverterId!, body }).unwrap();
      navigate(`../home?direction=forward`);
    } catch (e) {
      console.error(e);
      toast({
        title: t('VPP.error joining program'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
        duration: 3000,
      });
    }
  }

  return (
    <Box>
      <Flex position="absolute" right={0}>
        <IconButton
          aria-label="vpp settings"
          variant="unstyled"
          height="auto"
          icon={<BsThreeDots size={26} />}
          data-testid="device-specs-button"
          onClick={onOpenOptionDrawer}
          hidden={!isLoaded || programOptions.length === 0}
          right={0}
        />
      </Flex>
      <OptionSelectAlertDrawer
        isOpen={isOptionDrawerOpen}
        onClose={onCloseOptionDrawer}
        onSelectOption={handleSelectOptionDrawerOption}
        options={programOptions}
        title={t('VPP.nav title')}
      />

      <CustomVppIcon maxH="175px" iconColor={iconColor} />

      <Heading textAlign="center" my={2} lineHeight={1}>
        {t('VPP.select program title')}
      </Heading>
      <Text textAlign="center" color={textColor} mb={2}>
        {t('VPP.select program subtitle')}
      </Text>

      <Divider my={2} />

      {isLoaded ? (
        <>
          <Text>{t('VPP.join the vpp and earn')}</Text>
          <Text mb={2}>{t('VPP.your battery will help')}</Text>
          <Text fontWeight="bold">{t('VPP.benefits')}</Text>
          <Text>- {t('VPP.power the grid')}</Text>
          <Text mb={6}>- {t('VPP.earn during events')}</Text>
          <Center>
            <ArcButton
              arcColor="#3DCD58"
              minWidth="80%"
              mb={4}
              py={6}
              onClick={handleSubmit}
              data-testid="join-program-button"
            >
              {t('VPP.join program')}
            </ArcButton>
          </Center>
          <Text mx={8} mb={6} textAlign="center">
            {t('VPP.by clicking join program')}{' '}
            <Link fontWeight={600} data-testid="privacy-notice-link" color={linkColor} onClick={onPrivacyOpen}>
              {t('Privacy Notice.privacy notice')}
            </Link>{' '}
            {t('Set Address.and')}{' '}
            <Link fontWeight={600} data-testid="terms-of-use-link" color={linkColor} onClick={onTermsOpen}>
              {t('Common.terms of use')}
            </Link>{' '}
            {t('VPP.for se home and vpp')}
          </Text>
          <PrivacyNoticeModal isOpen={isPrivacyOpen} onClose={onPrivacyClose} />
          <TermsOfUseModal isOpen={isTermsOpen} onClose={onTermsClose} />
        </>
      ) : (
        <CenteredLoader text={`${t('Common.loading')}...`} mt="50%" />
      )}
    </Box>
  );
}
