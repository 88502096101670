// UI configuration related constants for Sense Only homepage components
export const MAX_NUMBER_OF_TOP_CONSUMER_DEVICES = 5;
export const TOP_CONSUMERS_COLORS = ['#DB5E52', '#F19F39', '#FEDC38', '#3DCD57', '#42B4E6'];
export const OTHER_DEVICES_COLOR = '#2C3994';

export const SOURCES_COLOURS = { solar: '#27AE60', grid: '#DB5E52', battery: '#42B4E6', consumption: '#9B51E0' };

export const CHART_SPACER = {
  name: '__hidden',
  y: 3,
  color: 'none',
  states: { hover: { enabled: false } },
  dataLabels: { enabled: false },
};

export const CHART_DEFAULT_OPTIONS = {
  chart: {
    type: 'pie',
    backgroundColor: 'transparent',
    shadow: false,
    height: 300,
    width: 300,
  },
  credits: {
    enabled: false,
  },
  title: '',
  plotOptions: {
    pie: {
      animation: false,
      innerSize: 220,
      dataLabels: {
        enabled: false,
      },
      borderWidth: 10,
      borderColor: null,
      enableMouseTracking: false,
    },
  },
  series: [
    {
      data: {
        name: 'background',
        y: 100,
      },
    },
  ],
};
