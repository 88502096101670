import React, { MouseEvent, TouchEvent } from 'react';
import { Center, ComponentWithAs, Flex, IconProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import SEHomeCard from '../../../../common/components/SEHomeCard';

interface Props {
  onClick: (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => void;
  borderColor: string;
  name: string;
  power: number;
  cost?: number;
  children?: React.ReactNode;
  Icon: ComponentWithAs<'svg', IconProps>;
  costLabel?: string;
  dataTestId?: string;
}

export function SensePowerCard({
  onClick,
  borderColor,
  name,
  power,
  cost,
  children,
  Icon,
  costLabel,
  dataTestId,
}: Props) {
  const { t } = useTranslation();
  const powerFormatted = Intl.NumberFormat('en', { maximumFractionDigits: 2 }).format(power);
  const costFormatted = cost !== undefined ? formatDollarsCents(cost) : '';

  return (
    <SEHomeCard
      cursor="pointer"
      p={4}
      borderTopLeftRadius="5px"
      mb={2}
      onClick={onClick}
      borderLeftColor={borderColor}
      borderLeftWidth={'5px'}
    >
      <Flex align="center" data-testid={dataTestId}>
        <Center marginRight="auto">
          <Icon w={10} h={10} mr={4} />
          <Flex direction="column">
            <Text fontSize={17} fontWeight={400} lineHeight={1.2} overflowWrap="anywhere">
              {name}
            </Text>
            <Text fontSize={15} fontWeight={400} lineHeight={1.1}>
              {t('Home Screen.current use')}: {powerFormatted} kW
            </Text>
            {cost !== undefined && (
              <Text fontSize={15} fontWeight={400} lineHeight={1.1}>
                {costLabel ?? `${t('Home Screen.todays cost')}: `}
                {costFormatted}
              </Text>
            )}
          </Flex>
        </Center>
        {children}
      </Flex>
    </SEHomeCard>
  );
}
