import { Auth } from 'aws-amplify';

import { IS_DEMO_LOGIN } from '../../../common/constants';
import { ENV_TYPE_SHORTHAND } from '../../../env-type';
import { REGION_SHORTHAND } from '../../../region-type';
import demoLiveData from '../../demo-login/stubs/sense-live-data.json';
import { invalidateMeterQueryData } from './liveDataApi';
import { LiveDataMessage } from './types';

/**
 * This is a special query that uses a websocket to get live data. A breakdown of what happens:
 * 1. A component subscribes to this query by calling the hook
 * 2. The queryFn is called, which returns a dummy value to the cache
 * 3. The `onCacheEntry` callback is called, which creates a websocket connection and updates the cache
 *    with new values coming from the live data stream. This stream updates once every 500ms, and lasts for 5
 *    minutes. Once this time has elapsed, and the connection is still active, the server returns a `reconnect`
 *    type message -- if we're still connected, we just re-subscribe to the site.
 *
 * In the event of an unknown message structure (generally an error), we don't do anything, as there are multiple
 * no-op errors which come from the websocket (e.g. API gateway function timeout, which doesn't actually affect
 * the data stream).
 */

const WEBSOCKET_ENDPOINT = import.meta.env[
  `VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_WEBSOCKET_API_URL`
] as string;

let numTimesRetried = 0;
const MAX_WEBSOCKET_RETRY_ATTEMPTS = 1;
const WEBSOCKET_RETRY_TIMEOUT_MS = 5_000;

export const liveDataWebhookQueryFn = () => {
  if (IS_DEMO_LOGIN) {
    return {
      data: {
        ...(demoLiveData.payload as any),
        isLoaded: true,
        isError: false,
        error: null,
      },
    };
  }

  // We don't actually use the siteId param, it's just for caching.
  return {
    data: {
      ac_load_net: 0,
      consumption: 0,
      devices: [],
      switches: [],
      epoch: 0,
      solar: 0,
      hybrid_inverter: 0,
      isLoaded: false,
      isError: false,
      error: null,
    },
  };
};

export const onCacheEntryAddedHandler = async (
  arg: { siteId: number; queryIdentifier: number },
  api: {
    // TODO make types more specific.
    updateCachedData: any;
    cacheDataLoaded: any;
    cacheEntryRemoved: any;
  }
) => {
  const { updateCachedData, cacheDataLoaded, cacheEntryRemoved } = api;
  if (IS_DEMO_LOGIN) {
    updateCachedData(() => ({
      ...(demoLiveData.payload as any),
      isLoaded: true,
      isError: false,
      error: null,
    }));
    return;
  }
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const ws = new WebSocket(`${WEBSOCKET_ENDPOINT}/?token=${token}`);

  /*
    The following timeout is used to keep track of WS messages being received.
    Once the timeout elapses - an error is reported to the cache.

    This helps handle the following scenarios:
    1. The WS stops sending data unexpectedly (without issuing a `close` message).
       If it resumes sending data, the timeout is reset.

    2. The user's network connection disconnects then reconnects.
       If the network reconnects, the timeout is reset.
  */
  let websocketMessageTimeout: NodeJS.Timeout;

  function resetWebsocketMessageTimer() {
    if (websocketMessageTimeout) {
      clearTimeout(websocketMessageTimeout);
    }

    websocketMessageTimeout = setTimeout(async () => {
      // Once maximum retries is exceeded, show an error message to the user and let them manually retry.
      if (numTimesRetried <= MAX_WEBSOCKET_RETRY_ATTEMPTS) {
        invalidateMeterQueryData();
        numTimesRetried++;
      } else {
        numTimesRetried = 0;
        updateCachedData((draft: object) => ({
          ...draft,
          isLoaded: true,
          isError: true,
          error: 'WEBSOCKET_ERROR',
        }));
      }
    }, WEBSOCKET_RETRY_TIMEOUT_MS);
  }

  try {
    await new Promise<void>((res, rej) => {
      ws.onopen = () => {
        res();
      };

      ws.onerror = () => {
        rej('WEBSOCKET_CONNECT_ERROR');
      };
    });
  } catch {
    updateCachedData((draft: object) => ({
      ...draft,
      isLoaded: true,
      isError: true,
      error: 'WEBSOCKET_CONNECT_ERROR',
    }));
    return;
  }

  // Subscribe to the site
  ws.send(JSON.stringify({ action: 'subscribeSiteLiveData', clipsal_solar_id: arg.siteId }));

  const handleDisconnect = () => {
    updateCachedData((draft: object) => ({
      ...draft,
      isLoaded: true,
      isError: true,
      error: 'NETWORK_ERROR',
    }));
  };

  const handleReconnect = () => {
    resetWebsocketMessageTimer();
  };

  window.addEventListener('offline', handleDisconnect);
  window.addEventListener('online', handleReconnect);

  try {
    // wait for the initial query to resolve before proceeding
    await cacheDataLoaded;

    const handleWebSocketMessage = (event: MessageEvent) => {
      try {
        resetWebsocketMessageTimer();
        const data: LiveDataMessage = JSON.parse(event.data);

        if (data?.type == 'realtime_update') {
          numTimesRetried = 0; // Reset number of retry attempts if we got data from the WS
          updateCachedData(() => ({
            ...data.payload,
            isLoaded: true,
            isError: false,
            error: null,
          }));
        } else if (data?.type == 'reconnect') {
          ws.send(JSON.stringify({ action: 'subscribeSiteLiveData', clipsal_solar_id: arg.siteId }));
        } else if (data?.type == 'error') {
          updateCachedData((draft: object) => ({
            ...draft,
            isLoaded: true,
            isError: true,
            error: 'WEBSOCKET_ERROR',
          }));
        } else if (data?.type == 'close') {
          ws.close();
          // No-op
        } else if (data?.type == 'open') {
          // No-op
        } else if (data?.type == 'info') {
          // No-op
        } else {
          console.log('UNKNOWN MESSAGE: ', data);
        }
      } catch (error) {
        ws.close();
        updateCachedData((draft: object) => ({
          ...draft,
          isLoaded: true,
          isError: true,
          error: 'WEBSOCKET_ERROR',
        }));
      }
    };
    ws.addEventListener('message', handleWebSocketMessage);
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }

  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;

  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  ws.send(JSON.stringify({ action: 'unsubscribeSiteLiveData', clipsal_solar_id: arg.siteId }));
  ws.close();
};
