import { useMemo } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { formatInTimeZone } from 'date-fns-tz';
import { AxisLabelsFormatterContextObject, Options, SeriesAreaOptions } from 'highcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getChartTimezoneOffset } from '../../../../utils/common/common-utils';
import { getTimeFormat } from '../../../account/settings/settings-helpers';
import { useLiveData } from '../../../site/live-data/liveDataApi';
import { selectSite } from '../../../site/siteSlice';
import { SOURCES_COLOURS } from '../constants';
import { LiveDataCache, useLiveCachedData } from './use-live-cached-data';

export function useLiveChartData(width: number | null) {
  const timeFormat = getTimeFormat();
  const { timezone, is_consumption_site, devices } = useSelector(selectSite);
  const isSolar = !is_consumption_site;
  const isBattery = devices.some((device) => device.device_type === 'BATTERY_PACK');
  const markerColor = useColorModeValue('#000', '#BCBCBC');
  const { t } = useTranslation();
  const { data: liveData, isMeterError, isLoading, isFetching, error } = useLiveData();
  const data = useLiveCachedData(liveData);

  const series = useMemo(() => {
    if (isLoading) return [];

    const seriesBase = {
      type: 'area',
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      },
      animation: false,
      connectNulls: false,
      connectEnds: false,
    };

    return [
      ...(isSolar
        ? [
            {
              ...seriesBase,
              name: t('Solar.solar'),
              color: SOURCES_COLOURS.solar,
              fillColor: {
                ...seriesBase.fillColor,
                stops: [
                  [0, 'rgba(39, 174, 96, 0.3)'],
                  [1, 'rgba(39, 174, 96, 0)'],
                ],
              },
              data: getPowerUsageData(data, 'solar'),
            },
          ]
        : ([] as SeriesAreaOptions[])),
      {
        ...seriesBase,
        name: t('Grid.grid'),
        color: SOURCES_COLOURS.grid,
        fillColor: {
          ...seriesBase.fillColor,
          stops: [
            [0, 'rgba(219, 94, 82, 0.3)'],
            [1, 'rgba(219, 94, 82, 0)'],
          ],
        },
        data: getPowerUsageData(data, 'grid'),
      },
      {
        ...seriesBase,
        name: t('Live Power Flow.consumption'),
        color: SOURCES_COLOURS.consumption,
        fillColor: {
          ...seriesBase.fillColor,
          stops: [
            [0, 'rgba(155, 81, 224, 0.3)'],
            [1, 'rgba(155, 81, 224, 0)'],
          ],
        },
        data: getPowerUsageData(data, 'consumption'),
      },
      ...(isBattery
        ? [
            {
              ...seriesBase,
              name: t('Battery.battery'),
              color: SOURCES_COLOURS.battery,
              fillColor: {
                ...seriesBase.fillColor,
                stops: [
                  [0, 'rgba(219, 94, 82, 0.3)'],
                  [1, 'rgba(219, 94, 82, 0)'],
                ],
              },
              data: getPowerUsageData(data, 'battery'),
            },
          ]
        : ([] as SeriesAreaOptions[])),
    ] as SeriesAreaOptions[];
  }, [isLoading, data]);

  const options = useMemo<Options>(() => {
    const tooltipDateFormat = timeFormat === '24HR' ? '%H:%M' : '%l:%M %P';
    const labelsStyle = { fontSize: '11px', color: markerColor };
    const xAxisTickFormat = timeFormat === '24HR' ? 'HH:mm' : 'h:mm aaa';
    const xAxisTickInterval = 60 * 1000;
    const xAxisLabelsFormatter = (data: AxisLabelsFormatterContextObject) =>
      formatInTimeZone(new Date(data.value ?? 0), timezone, xAxisTickFormat);

    return {
      chart: {
        type: 'datetime',
        height: 300,
        backgroundColor: 'rgba(255,255,255,0)',
        spacingLeft: 2,
        spacingRight: 2,
        style: { marginRight: '0px', marginLeft: '0px' },
        width,
        animation: false,
      },
      title: {
        text: '',
      },
      series,
      tooltip: {
        valueSuffix: ' kW',
        valueDecimals: 2,
        shared: true,
        xDateFormat: tooltipDateFormat,
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderRadius: 50,
        borderWidth: 0,
        shadow: false,
        padding: 12,
        style: {
          color: 'white',
          fontSize: '16px',
        },
        headerFormat: '<span style="font-size: 16px; font-weight: bold;">{point.key}</span><br/>',
        pointFormat: `
          <span style="color:{point.color}; font-size: 28px;">∎</span>
          <span style="font-size: 16px;">{series.name}: {point.y}</span><br/>
        `,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        // Chart works the best if min/max is not set.
        // min: xAxisMin,
        // max: xAxisMax,
        accessibility: { enabled: true, description: t('Activity.time of the day') },
        // shows long crosshair when hovered in chart
        crosshair: {
          color: 'rgb(204, 204, 204)',
        },
        endOnTick: false,
        // style ticks
        tickWidth: 2,
        tickLength: 6,
        tickInterval: xAxisTickInterval,
        tickColor: '#C6C6C6',
        type: 'datetime',
        // removes default padding in the chart
        minPadding: 0,
        maxPadding: 0,
        lineWidth: 0,
        labels: {
          style: labelsStyle,
          formatter: xAxisLabelsFormatter,
        },
      },
      yAxis: {
        gridLineDashStyle: 'ShortDash',
        accessibility: { enabled: true, description: t('Activity.usage in kw') },
        title: {
          text: '',
        },
        labels: {
          style: labelsStyle,
          formatter: function (data) {
            if (data.value === 0) return '<p style="font-size: 16px;">0</p>';
            return `${+Number(data.value).toFixed(3)} kW`;
          },
        },
      },
      legend: {
        enabled: false,
      },
      time: {
        getTimezoneOffset: (timestamp: number) => getChartTimezoneOffset(timestamp, timezone),
      },
    };
  }, [width, timezone, markerColor, series]);

  return {
    options,
    data,
    isLoading,
    isFetching,
    isError: isMeterError,
    error,
  };
}

function getPowerUsageData(data: LiveDataCache[], key: keyof LiveDataCache) {
  return data.map((interval) => ({
    x: new Date(interval.last_updated).getTime(),
    y: interval[key] as number,
  }));
}
