import React, { useMemo } from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Divider,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsThreeDots } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import OptionSelectAlertDrawer from '../../../common/components/OptionSelectAlertDrawer';
import { selectSite } from '../../site/siteSlice';
import CustomVppIcon from './CustomVPPIcon';
import { useGetVppProgramsStatusQuery } from './vppApi';
import { VPPApplicationGuide } from './VPPApplicationGuide';

export function ActiveVPP() {
  const { devices } = useSelector(selectSite);
  const inverterId = useMemo(() => devices!.find((device) => device.device_type === 'INVERTER')?.id, [devices]);
  const { data: programsStatus } = useGetVppProgramsStatusQuery(inverterId!);
  const program = useMemo(
    () => programsStatus?.find((programStatus) => programStatus?.equipment_program?.status === 'Enrolled'),
    [programsStatus]
  );
  const textColor = useColorModeValue('customGrey.900', 'customGrey.300');
  const inactiveEventiconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const activeEventIconColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const bgColor = useColorModeValue('#fff', '#111');
  const { isOpen: isOptionDrawerOpen, onOpen: onOpenOptionDrawer, onClose: onCloseOptionDrawer } = useDisclosure();
  const {
    isOpen: isLearnMoreModalOpen,
    onOpen: onLearnMoreModalOpen,
    onClose: onCloseLearnMoreModal,
  } = useDisclosure();
  const { t } = useTranslation();

  // TODO: Update below when the API provides the event status
  const isVppEventInProgress = false;

  function handleSelectOptionDrawerOption(value: string) {
    if (value === 'LEARN_MORE') {
      onLearnMoreModalOpen();
    }
    onCloseOptionDrawer();
  }

  return (
    <>
      <Flex position="absolute" right={0}>
        <IconButton
          aria-label="vpp settings"
          variant="unstyled"
          height="auto"
          icon={<BsThreeDots size={26} />}
          data-testid="device-specs-button"
          onClick={onOpenOptionDrawer}
        />
      </Flex>
      <OptionSelectAlertDrawer
        isOpen={isOptionDrawerOpen}
        onClose={onCloseOptionDrawer}
        onSelectOption={handleSelectOptionDrawerOption}
        options={[
          {
            value: 'LEARN_MORE',
            label: t('VPP.learn more'),
          },
        ]}
        title={t('VPP.more about vpp')}
      />
      <CustomVppIcon maxH="175px" iconColor={isVppEventInProgress ? activeEventIconColor : inactiveEventiconColor} />

      <Heading size="lg" textAlign="center" my={2}>
        {program?.equipment_program?.program.name}
      </Heading>

      <Text textAlign="center" color={textColor} lineHeight={1.2}>
        {isVppEventInProgress ? t('VPP.participating subtitle') : t('VPP.not participating subtitle')}
      </Text>
      <Text textAlign="center" color={textColor} mb={4} lineHeight={1.2}>
        {isVppEventInProgress ? t('VPP.participating description') : t('VPP.not participating description')}
      </Text>

      <Divider my={2} />

      {/* TODO: Add events below when the API has these available */}

      {/* <Text>{t('VPP.current event').toUpperCase()}</Text>
      <SimpleGrid gridGap={5} mt={2} mb={4} minChildWidth="45%">
        <DataSummaryCard title="Start Time" isLoaded={!isLoading} value="12:00" suffix={'PM'} />
        <DataSummaryCard title={'End Time'} isLoaded={!isLoading} value={'6:00'} suffix={'PM'} />
      </SimpleGrid>
      <Divider my={2} />
      <Text>{t('VPP.upcoming events').toUpperCase()}</Text> */}

      <Modal
        scrollBehavior={'inside'}
        isOpen={isLearnMoreModalOpen}
        onClose={onCloseLearnMoreModal}
        size="full"
        motionPreset="slideInRight"
      >
        <ModalContent data-testid="learn-more-modal" backgroundColor={bgColor} top={'env(safe-area-inset-top)'}>
          <ModalCloseButton
            position={'absolute'}
            left={6}
            mt={1.5}
            fontSize={'17px'}
            data-testid="privacy-notice-back-button"
            onClick={onCloseLearnMoreModal}
          >
            <ChevronLeftIcon w={'26px'} h={'26px'} />
          </ModalCloseButton>
          <ModalBody>
            <VPPApplicationGuide />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
