import React, { useCallback, useMemo, useState } from 'react';
import { Box, Center, Divider, ListItem, Text, UnorderedList, useColorModeValue } from '@chakra-ui/react';
import Highcharts, { Options } from 'highcharts';
import Chart from 'highcharts-react-official';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DayType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import { COLOURS } from 'clipsal-cortex-utils/src/constants/colors';

import ArcButton from '../../../common/components/ArcButton';
import SEHomeCard from '../../../common/components/SEHomeCard';
import ConfirmTariffPageBase from '../ConfirmTariffPageBase';
import SeasonMultiToggleSwitch from '../SeasonMultiToggleSwitch';
import { adjustSeasonDate, formatDollars } from '../tariff-helpers';
import { TOUTariffRatesPerType } from '../tariff-types';
import { useHandleSubmitForm } from '../useHandleSubmitForm';
import { useTariffFormContext } from '../useTariffFormContext';
import {
  TOU_RATE_TYPE_TO_FORM_KEY,
  TOU_TARIFF_FORM_KEYS,
  useTranslatedTouRateTypeToConfig,
} from './tou-tariff-constants';

export const ConfirmTOUTariff = () => {
  const { control, isDirtyForm } = useTariffFormContext();
  const tariff = useWatch({ control, name: `tariff` });
  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
  const { handleSubmitForm, isSubmitting } = useHandleSubmitForm();
  const { i18n, t } = useTranslation();

  const { weekendChartOptions, weekdayChartOptions, allWeekChartOptions } = useChartOptions(selectedSeasonIndex);

  const { formattedStartDate, formattedEndDate } = useMemo(() => {
    const startDate = adjustSeasonDate(
      tariff?.seasons[selectedSeasonIndex].fromMonth,
      tariff?.seasons[selectedSeasonIndex].fromDate
    );

    const endDate = adjustSeasonDate(
      tariff?.seasons[selectedSeasonIndex].toMonth,
      tariff?.seasons[selectedSeasonIndex].toDate
    );

    const formattedStartDate = startDate.toLocaleDateString(i18n.language, { month: 'short', day: 'numeric' });
    const formattedEndDate = endDate.toLocaleDateString(i18n.language, { month: 'short', day: 'numeric' });

    return { formattedStartDate, formattedEndDate };
  }, [selectedSeasonIndex]);

  const { isAllWeekTariff, hasExportRate, hasDeliveryCharge } = useMemo(() => {
    const allWeekTariffs = tariff?.rates?.[selectedSeasonIndex]?.allWeek || {};
    const isAllWeekTariff = !!Object.values(allWeekTariffs).flat().length;
    const hasExportRate = !!tariff?.rates?.[selectedSeasonIndex]?.export?.length;
    const hasDeliveryCharge = !!tariff?.deliveryCharge;
    return { isAllWeekTariff, hasExportRate, hasDeliveryCharge };
  }, [selectedSeasonIndex]);

  return (
    <ConfirmTariffPageBase>
      <SEHomeCard borderTopLeftRadius={'5px'} borderBottomRightRadius={'39px'} pr={0} pb={4}>
        <Text fontSize={20}>{tariff?.planName}</Text>

        <Text fontSize={17} fontWeight={500}>
          {t('Energy Rates.time of use plan').toUpperCase()}
        </Text>
        <Divider bg={'#9797974D'} mb={2} />

        <Box
          color={'#111111'}
          _dark={{
            color: '#CBCBCB',
          }}
        >
          {hasDeliveryCharge && (
            <>
              <Text mt={4}>
                {tariff?.deliveryCharge?.chargePeriod && t(`Energy Rates.labels.${tariff.deliveryCharge.chargePeriod}`)}{' '}
                {t(`Energy Rates.delivery charge`)}
              </Text>
              <Text>
                ${tariff?.deliveryCharge?.rateBands[0].rate} /{' '}
                {tariff?.deliveryCharge?.chargePeriod && t(`Energy Rates.units.${tariff.deliveryCharge.chargePeriod}`)}
              </Text>
            </>
          )}

          {isAllWeekTariff ? (
            <Box data-testid="all-week-rates">
              <Text mt={4}>{t('Energy Rates.import rate')}</Text>
              <ImportRatePrices ratesPerType={tariff?.rates?.[selectedSeasonIndex]?.allWeek} dayType={'allWeek'} />
            </Box>
          ) : (
            <Box data-testid="weekday-weekend-rates">
              <Text mt={4}>{t('Energy Rates.weekday import rate')}</Text>
              <ImportRatePrices ratesPerType={tariff?.rates?.[selectedSeasonIndex]?.weekday} dayType={'weekday'} />
              <Text mt={4}>{t('Energy Rates.weekend import rate')}</Text>
              <ImportRatePrices ratesPerType={tariff?.rates?.[selectedSeasonIndex]?.weekend} dayType={'weekend'} />
            </Box>
          )}

          {hasExportRate && (
            <>
              <Text mt={4}>{t('Energy Rates.export rate')}</Text>
              <Text>
                {t('Energy Rates.credit')}: ${tariff?.rates?.[selectedSeasonIndex]?.export?.[0]?.rateBands[0].rate} /
                kWh
              </Text>
            </>
          )}
        </Box>
      </SEHomeCard>

      <SEHomeCard borderTopLeftRadius={'5px'} borderBottomRightRadius={'39px'} my={4}>
        <SeasonMultiToggleSwitch
          value={selectedSeasonIndex}
          onChange={(newIndex) => setSelectedSeasonIndex(newIndex)}
        />
        <Text my={4}>
          {formattedStartDate} - {formattedEndDate}
        </Text>

        {isAllWeekTariff ? (
          <Box>
            <Chart highcharts={Highcharts} options={allWeekChartOptions} />
            <ImportRateChartLegend ratesPerType={tariff?.rates?.[selectedSeasonIndex]?.allWeek} dayType={'allWeek'} />
          </Box>
        ) : (
          <>
            <Text>
              {t('Common.mon').toUpperCase()} - {t('Common.fri').toUpperCase()}
            </Text>
            <Box>
              <Chart highcharts={Highcharts} options={weekdayChartOptions} />
              <ImportRateChartLegend ratesPerType={tariff?.rates?.[selectedSeasonIndex]?.weekday} dayType={'weekday'} />
            </Box>
            <Text mt={4}>
              {t('Common.sat').toUpperCase()} - {t('Common.sun').toUpperCase()}
            </Text>
            <Box>
              <Chart highcharts={Highcharts} options={weekendChartOptions} />
              <ImportRateChartLegend ratesPerType={tariff?.rates?.[selectedSeasonIndex]?.weekend} dayType={'weekend'} />
            </Box>
          </>
        )}
      </SEHomeCard>

      <Center mt="auto" mb={4} flexDir={'column'}>
        <ArcButton
          arcColor="#3DCD58"
          minWidth="280px"
          py={6}
          isLoading={isSubmitting}
          data-testid="primary-button"
          onClick={handleSubmitForm}
        >
          {isDirtyForm ? t('Energy Rates.confirm rates') : t('Energy Rates.back to tariff list')}
        </ArcButton>
      </Center>
    </ConfirmTariffPageBase>
  );
};

const checkIfHourLiesInRange = (hour: number, fromHour: number, toHour: number) => {
  if (fromHour === toHour) {
    return true; // same start and end hour means it's a 24 hour period
  } else if (fromHour < toHour) {
    return hour >= fromHour && hour < toHour;
  } else {
    return hour >= fromHour || hour < toHour;
  }
};

function useChartOptions(selectedSeasonIndex: number) {
  const { control } = useTariffFormContext();
  const tariffRates = useWatch({ control, name: `tariff.rates` });
  const seasonRates = tariffRates?.[selectedSeasonIndex];
  const textColor = useColorModeValue('black', 'white');
  const { t } = useTranslation();
  const TOU_RATE_TYPE_TO_CONFIG = useTranslatedTouRateTypeToConfig();

  const getSeries = useCallback(
    (dayType: DayType) => {
      const offPeakRates = seasonRates?.[dayType]?.offPeak || [];
      const partialPeakRates = seasonRates?.[dayType]?.partialPeak || [];
      const peakRates = seasonRates?.[dayType]?.peak || [];

      const allRates = [...offPeakRates, ...partialPeakRates, ...peakRates];
      if (!allRates.length) return [];

      // Return a separate series for each rate in allRates
      return allRates.map((rateObj) => {
        const touType = rateObj.timeOfUse?.touRateType || 'OFF_PEAK';
        const formKey = TOU_RATE_TYPE_TO_FORM_KEY[touType];
        const colorIndex = TOU_RATE_TYPE_TO_CONFIG[formKey].colorIndex;

        // Build an array of 24 data points (hour = 0..23)
        const data = Array.from({ length: 24 }, (_, hour) => {
          const isHourInRange = rateObj.timeOfUse?.periods?.some(({ fromHour, toHour }) =>
            checkIfHourLiesInRange(hour, fromHour, toHour)
          );

          return {
            borderColor: COLOURS[colorIndex],
            x: hour,
            y: isHourInRange ? rateObj.rateBands?.[0]?.rate ?? 0 : 0,
          };
        });

        return {
          name: rateObj?.timeOfUse?.touRateType,
          type: 'column',
          color: COLOURS[colorIndex],
          data,
        };
      });
    },
    [seasonRates]
  );

  return useMemo(() => {
    const options: Options = {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        height: 90,
        animation: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: [''],
        labels: {
          useHTML: true,
          style: { color: textColor, fontSize: '12px' },
          formatter: function () {
            const hour = Number(this.value);
            if (hour === 0) return '12';
            if (hour > 12) return `${hour - 12}`;
            return `${hour}`;
          },
        },
        crosshair: true,
        lineColor: 'transparent',
        accessibility: { enabled: true, description: t('Energy Rates.rates') },
      },
      yAxis: {
        title: {
          text: '',
        },
        tickAmount: 4,
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        animation: false,
        valueSuffix: '¢',
        style: {
          color: 'white',
          fontSize: '16px',
        },
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderRadius: 50,
        borderWidth: 0,
        shadow: false,
        padding: 12,
        formatter: function () {
          return `<span style="color:${this.color}; font-size: 24px;">∎</span>${
            this.series.name
          }: <span>${formatDollars(Number(this.y) || 0)} ${t('Energy Rates.per')} kWh</span><br/>`;
        },
      },
      plotOptions: {
        column: {
          borderRadius: 0,
          pointWidth: 6,
        },
      },
      legend: {
        enabled: false,
        align: 'left',
        itemMarginTop: 6,
        symbolRadius: 0,
      },
      credits: {
        enabled: false,
      },
      series: [],
    };

    return {
      weekdayChartOptions: { ...options, series: getSeries('weekday') },
      weekendChartOptions: { ...options, series: getSeries('weekend') },
      allWeekChartOptions: { ...options, series: getSeries('allWeek') },
    };
  }, [selectedSeasonIndex]);
}

type ImportRatePricesProps = {
  ratesPerType?: TOUTariffRatesPerType;
  dayType: DayType;
};

function ImportRatePrices({ ratesPerType, dayType }: Readonly<ImportRatePricesProps>) {
  const { t } = useTranslation();
  const TOU_RATE_TYPE_TO_CONFIG = useTranslatedTouRateTypeToConfig();

  return (
    <UnorderedList ml={6}>
      {TOU_TARIFF_FORM_KEYS.map((key) => {
        return ratesPerType?.[key]?.map(({ rateBands }, rateIndex, arr) => {
          const rateBand = rateBands[0];
          return (
            <ListItem key={`${dayType}-${key}-${rateBand.sequenceNumber}-${rateIndex}`}>
              {TOU_RATE_TYPE_TO_CONFIG[key].title} {t('Energy Rates.price')}
              {arr.length > 1 ? ` ${rateIndex + 1}` : ''}: ${rateBand.rate} / kWh
            </ListItem>
          );
        });
      })}
    </UnorderedList>
  );
}

type ImportRateChartLegendProps = {
  ratesPerType?: TOUTariffRatesPerType;
  dayType: DayType;
};

function ImportRateChartLegend({ ratesPerType, dayType }: Readonly<ImportRateChartLegendProps>) {
  const TOU_RATE_TYPE_TO_CONFIG = useTranslatedTouRateTypeToConfig();

  return (
    <Center>
      <Box w="fit-content">
        {TOU_TARIFF_FORM_KEYS.map((key) => {
          return ratesPerType?.[key]?.map(({ rateBands }, rateIndex, arr) => {
            const rateBand = rateBands[0];
            const { title, colorIndex } = TOU_RATE_TYPE_TO_CONFIG[key];
            return (
              <Center w="100%" key={`${dayType}-${key}-${rateBand.sequenceNumber}-${rateIndex}`}>
                <Box w={2.5} h={2.5} bg={COLOURS[colorIndex]} mr={2} />
                <Text ml="auto">
                  <Box as="span" textTransform={'uppercase'}>
                    {title}
                  </Box>{' '}
                  {arr.length > 1 ? `${rateIndex + 1}` : ''} - ${rateBand.rate} kWh
                </Text>
              </Center>
            );
          });
        })}
      </Box>
    </Center>
  );
}
