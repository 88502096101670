import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BatteryReserve, BatteryReserveData, BatteryReservePayload } from 'clipsal-cortex-types/src/api';

import { baseApi } from '../../app/services/baseApi';
import { selectSite } from '../site/siteSlice';
import { GENERIC_BATTERIES_MANUFACTURER_IDS } from './constants';

// TODO combine these into a single endpoint when API is updated.
export const batteryReserveApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSaturnBatteryReserve: build.query<BatteryReserve, number>({
      query: (deviceId) => `/v1/site_devices/${deviceId}/battery_reserve`,
      providesTags: ['SaturnBatteryReserve'],
    }),
    updateSaturnBatteryReserve: build.mutation<BatteryReserve, { deviceId: number; body: BatteryReservePayload }>({
      query: ({ deviceId, body }) => ({
        url: `/v1/site_devices/${deviceId}/battery_reserve`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['SaturnBatteryReserve'],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        // Optimistic update so the user gets fast feedback. The mutation happens in the background.
        const { deviceId, body } = arg;
        const patchResult = dispatch(
          batteryReserveApi.util.updateQueryData('getSaturnBatteryReserve', deviceId, (draft) => {
            // Update this setting in the drafted state. Note that immer provides mutability here!
            draft.pending = { reserve_percentage: body.reserve_percentage };
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    getGenericBatteryReserve: build.query<BatteryReserveData, number>({
      query: (deviceId) => `/v1/batteries/${deviceId}/reserve`,
      providesTags: ['GenericBatteryReserve'],
    }),
    updateGenericBatteryReserve: build.mutation<BatteryReserveData, { deviceId: number; body: BatteryReservePayload }>({
      query: ({ deviceId, body }) => ({
        url: `/v1/batteries/${deviceId}/reserve`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GenericBatteryReserve'],
    }),
  }),
});

export const {
  useGetSaturnBatteryReserveQuery,
  useUpdateSaturnBatteryReserveMutation,
  useGetGenericBatteryReserveQuery,
  useUpdateGenericBatteryReserveMutation,
} = batteryReserveApi;

export const useGetBatteryId = () => {
  const { devices } = useSelector(selectSite);
  const inverterIdSaturn = useMemo(() => devices.find(({ device_type }) => device_type === 'INVERTER')?.id, [devices]);
  const batteryIdGeneric = useMemo(
    () =>
      devices.find(
        ({ manufacturer_id, device_type }) =>
          GENERIC_BATTERIES_MANUFACTURER_IDS.includes(manufacturer_id) && device_type === 'BATTERY_PACK'
      )?.id,
    [devices]
  );
  return {
    isGeneric: !!batteryIdGeneric,
    batteryId: batteryIdGeneric ?? inverterIdSaturn,
  };
};

interface Options {
  skip?: boolean;
  pollingInterval?: number;
}

export const useGetBatteryReserve = ({ skip, pollingInterval }: Options) => {
  const { isGeneric, batteryId } = useGetBatteryId();
  const batteryReserveSaturn = useGetSaturnBatteryReserveQuery(batteryId!, {
    pollingInterval,
    skip: skip || isGeneric,
  });
  const batteryReserveGeneric = useGetGenericBatteryReserveQuery(batteryId!, {
    pollingInterval,
    skip: skip || !isGeneric,
  });

  if (isGeneric) {
    return batteryReserveGeneric;
  } else {
    return batteryReserveSaturn;
  }
};

export const useUpdateBatteryReserve = () => {
  const { isGeneric } = useGetBatteryId();
  const batteryReserveSaturn = useUpdateSaturnBatteryReserveMutation();
  const batteryReserveGeneric = useUpdateGenericBatteryReserveMutation();

  if (isGeneric) {
    return batteryReserveGeneric;
  } else {
    return batteryReserveSaturn;
  }
};
