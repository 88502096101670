// Slight difference from the generic live data summary (`site/{id}/live_data_summary`)
import { LiveDataSummary } from './api-live-data';

export type SaturnLiveDataSummary = Omit<LiveDataSummary, 'battery_duration_remaining'> & {
  grid_status: GridStatus;
  inverter_status: InverterStatus;
};

export enum GridStatus {
  INVALID = -1,
  UNDEFINED = 0,
  GRID_OUTAGE = 1,
  CLOSED = 2,
  FAULTED_OPEN = 3,
  FAULTED_CLOSED = 4,
  OVERRIDE_OPEN = 5,
  OVERRIDE_CLOSED = 6,
  E_STOP_OPEN = 7,
  E_STOP_CLOSED = 8,
}

export enum InverterStatus {
  NORMAL = 'NORMAL',
  NOT_CONNECTED = 'NOT_CONNECTED',
  OFFLINE = 'OFFLINE',
  FAULT = 'FAULT',
  SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',
  UNDEFINED = 'UNDEFINED',
}
