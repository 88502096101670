import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Device, GenericLiveBatteryData, SaturnLiveDataSummary } from 'clipsal-cortex-types/src/api';

import { MANUFACTURER_ID_SATURN } from '../devices/devices-helper';
import { useGetBatteryLiveDataQuery, useGetInverterLiveDataQuery } from '../site/live-data/liveDataApi';
import { selectBatteries, selectInverters, selectSite } from '../site/siteSlice';
import { GENERIC_BATTERIES_MANUFACTURER_IDS } from './constants';

export type BatteryStatus = 'CHARGING' | 'DISCHARGING' | 'IDLE' | 'EMPTY' | 'ERROR';

interface BatteryExtended {
  battery?: number;
  status: BatteryStatus;
  manufacturerId: number;
}

export type BatteryLiveData = (GenericLiveBatteryData | SaturnLiveDataSummary | undefined) & BatteryExtended;

// 0.1 kW above or below 0 is usually just noise
const BATTERY_THRESHOLD_KW = 0.1;

/**
 * Adds a computed 'status' property to the inverter live data summary API response, calling this endpoint on a 5-second
 * interval.
 */
export function useLiveBatteryData() {
  const { site_id: siteId } = useSelector(selectSite);
  const siteInverters = useSelector(selectInverters);
  const siteBatteries = useSelector(selectBatteries);

  const getDeviceId = useCallback(
    (devices: Device[], manufacturerIds: number[]) =>
      devices.find(({ manufacturer_id }) => manufacturerIds.includes(manufacturer_id)),
    []
  );

  const genericBattery = getDeviceId(siteBatteries, GENERIC_BATTERIES_MANUFACTURER_IDS);
  const genericBatteryId = genericBattery?.id ?? 0;
  const genericBatteryQueryResult = useGetBatteryLiveDataQuery(genericBatteryId, {
    pollingInterval: 5000,
    skip: !genericBatteryId,
  });
  const genericBatteryData = getData(genericBatteryQueryResult, genericBattery?.manufacturer_id);

  const saturnInverter = getDeviceId(siteInverters, [MANUFACTURER_ID_SATURN]);
  const saturnInverterId = saturnInverter?.id ?? 0;
  const saturnBatteryQueryResult = useGetInverterLiveDataQuery(siteId, {
    pollingInterval: 5000,
    skip: !saturnInverterId,
  });
  const saturnBatteryData = getData(saturnBatteryQueryResult, saturnInverter?.manufacturer_id);

  const baseData = {
    ...(genericBatteryQueryResult.data ?? {}),
    ...(saturnBatteryQueryResult.data ?? {}),
    status: saturnBatteryQueryResult.isError || genericBatteryQueryResult.isError ? 'ERROR' : 'IDLE',
    manufacturerId: 0,
  };

  // Saturn Battery has priority over Generic Battery
  return {
    ...genericBatteryQueryResult,
    ...saturnBatteryQueryResult,
    data: {
      ...baseData,
      ...(genericBatteryData ?? {}),
      ...(saturnBatteryData ?? {}),
    } as BatteryLiveData,
  };
}

function getData(
  {
    data,
    isLoading,
    isError,
  }: {
    data?: SaturnLiveDataSummary | GenericLiveBatteryData;
    isLoading: boolean;
    isError: boolean;
  },
  manufacturerId?: number
) {
  if (data && !isLoading && !isError) {
    let status = 'EMPTY';
    const { battery_soc_fraction } = data;
    const batteryW = 'battery_w' in data ? data.battery_w : undefined;
    const batteryKw = 'battery' in data ? data.battery : undefined;

    // If above or below 0 by the threshold, revert to 0
    let batteryReal = batteryW != null ? batteryW / 1000 : batteryKw ?? 0;
    if (Math.abs(batteryReal) < BATTERY_THRESHOLD_KW) {
      batteryReal = 0;
    }

    if (batteryReal < 0) status = 'DISCHARGING';
    if (batteryReal > 0) status = 'CHARGING';
    if (batteryReal === 0 && battery_soc_fraction && battery_soc_fraction > 0) status = 'IDLE';
    if (batteryReal === 0 && battery_soc_fraction == 0) status = 'EMPTY';

    return {
      ...data,
      battery: batteryReal,
      status,
      manufacturerId,
    };
  }
}
